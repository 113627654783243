import { Tab } from '@headlessui/react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTabs } from 'react-headless-tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as glassblockIcon } from 'src/assets/images/cart-icon.svg';
import { ReactComponent as exchangeIcon } from 'src/assets/images/exchange-icon.svg';
import { ReactComponent as sellerIcon } from 'src/assets/images/seller-icon.svg';
import { ReactComponent as walletIcon } from 'src/assets/images/wallet-icon.svg';
import { ReactComponent as explorerIcon } from 'src/assets/images/fa-magnifier.svg';
import { Env } from 'src/common/env';
import { SplitFeatureName } from 'src/config/splitio-sdk-config';
import { AppRoutes } from 'src/routes/app';

type HeaderNavItem = {
  route?: string;
  name: string;
  isSameDomain: boolean;
  id: string;
  order: number;
};

const walletItem: HeaderNavItem = {
  route: Env.exchangeLinkDevvxWallet,
  name: 'devvx wallet',
  isSameDomain: false,
  id: 'wallet',
  order: 3,
};

const exchangeItem: HeaderNavItem = {
  route: AppRoutes.EXCHANGE,
  name: 'devvexchange',
  isSameDomain: true,
  id: 'exchange',
  order: 4,
};

const initialItems: Array<HeaderNavItem> = [
  { route: AppRoutes.LANDING, name: 'marketplace', isSameDomain: true, id: 'marketplace', order: 1 },
  { route: Env.exchangeLinkDevvxExplorer, name: 'blockchain explorer', isSameDomain: false, id: 'explorer', order: 2 },
];

const defaultItem: HeaderNavItem = initialItems[0];

const iconComponents = {
  marketplace: glassblockIcon,
  seller: sellerIcon,
  wallet: walletIcon,
  exchange: exchangeIcon,
  explorer: explorerIcon,
};

const Icon = ({ props, className }: { props: string; className: string }) => {
  const SpecificIcon = iconComponents[props];
  return <SpecificIcon className={className} />;
};

export const HeaderNav = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState<Array<HeaderNavItem>>(initialItems);

  const { isReady } = useContext(SplitContext);
  const splitTreatments = useTreatments([
    SplitFeatureName.EXCHANGE_ENABLED_SPLIT,
    SplitFeatureName.BUYER_DEVVX_UX,
    // SplitFeatureName.EXPLORER_DEVVX_UX,
  ]);
  const checkSplit = useCallback(
    (feature: SplitFeatureName) => isReady && splitTreatments[feature]?.treatment === 'on',
    [isReady, splitTreatments],
  );

  useEffect(() => {
    if (checkSplit(SplitFeatureName.BUYER_DEVVX_UX)) {
      setItems((prev) => {
        if (prev.find((x) => x.id === walletItem.id)) return prev;
        return [...prev, walletItem];
      });
    }
  }, [checkSplit]);

  useEffect(() => {
    if (checkSplit(SplitFeatureName.EXCHANGE_ENABLED_SPLIT)) {
      setItems((prev) => {
        if (prev.find((x) => x.id === exchangeItem.id)) return prev;
        return [...prev, exchangeItem];
      });
    }
  }, [checkSplit]);

  // TODO: Enable the Explorer feature flag via split.io (overridden for now)
  // useEffect(() => {
  //   if (checkSplit(SplitFeatureName.EXPLORER_DEVVX_UX)) {
  //     setItems((prev) => {
  //       if (prev.find((x) => x.id === explorerItem.id)) return prev;
  //       return [...prev, explorerItem];
  //     });
  //   }
  // }, [checkSplit]);

  const [selectedTab, setSelectedTab] = useTabs(items.map((x) => x.name));

  useEffect(() => {
    const item = items.find((x) => {
      if (x.route !== defaultItem.route) {
        return location.pathname.slice(1).includes(x.id);
      }
      return false;
    });
    setSelectedTab(item?.name || defaultItem.name);
  }, [items, location, setSelectedTab]);

  const handleOnChange = (target: string) => {
    setSelectedTab(target);

    const item = items.find((x) => x.name === target && x.route) || defaultItem;
    if (!item.route) return;

    if (item.isSameDomain) {
      navigate(item.route);
    } else {
      window.location.href = item.route;
    }
  };

  return (
    <div>
      <Tab.Group
        selectedIndex={items.indexOf(items.find((x) => x.name === selectedTab) || defaultItem)}
        onChange={(index) => {
          handleOnChange(items[index].name);
        }}
      >
        <Tab.List className="flex gap-1 md:gap-4 h-full">
          {items
            .sort((a, b) => a.order - b.order)
            .map((item, index, array) => (
              <Tab as={Fragment} key={item.id}>
                {({ selected }) => (
                  <div className="flex items-center gap-2 hover:cursor-pointer focus:outline-none focus-visible:outline-none">
                    {index !== 0 && index !== array.length && (
                      <div className="md:mr-3 md:h-5 md:border-r md:border-gray-500" />
                    )}
                    <div
                      className={`items-center h-full flex md:gap-2 ${
                        selected ? 'md:border-b-4 md:border-blue-400 md:pt-1' : ''
                      }`}
                    >
                      <Icon
                        props={item.id}
                        className={`h-6 md:h-5 stroke-[0.5px] ${
                          selected ? 'stroke-blue-400 fill-blue-400' : 'stroke-white fill-white'
                        }`}
                      />
                      <div
                        className={`hidden md:flex font-poppins items-center ${
                          selected ? 'text-blue-400' : 'text-white'
                        }`}
                      >
                        <div className="whitespace-nowrap">{item.name.toUpperCase()}</div>
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
            ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
});
