/* eslint-disable camelcase */
import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { setSessionUuid } from 'src/api/exchangeClient';
import {
  createUser,
  getMyUser,
  getTradingPairs as exchangeGetTradingPairs,
  getBalances as exchangeGetBalances,
} from 'src/api/exchange';
import { ExchangeTradingPair, UserBalanceResponse } from 'src/interfaces';
import { AuthRoot, AuthRoutes } from 'src/routes/auth/auth-routes';
import { AppRoot } from '../../routes/app';
import { UserContext } from './UserContext';
import { FeatureFlags, FeatureFlagsEvents } from '../util';

export interface ExchangeUserProps {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  kyc_validated: boolean;
  legal_agreement_accepted: boolean;
  legal_agreement_accepted_timestamp: string;
  selling_orders: [];
  buying_orders: [];
  orders: [];
  username: string;
}

interface ExchangeUserContextInterface {
  exchangeUser: ExchangeUserProps | null;
  exchangeUserBuyingOrders: any[];
  exchangeUserOrders: any[];
  exchangeUserSellingOrders: any[];
  logIn: () => void;
  createExchangeUser: () => Promise<boolean> | Promise<undefined> | Promise<void> | undefined;
  logOut: () => void;
  setExchangeUser: (value) => void;
  setExchangeUserBuyingOrders: (value) => void;
  setExchangeUserSellingOrders: (value) => void;
  setExchangeUserOrders: (value) => void;
  loading: boolean;
  loadingInitial: boolean;
  tradingPairs: ExchangeTradingPair[];
  selectedCoinPair: ExchangeTradingPair | null;
  setSelectedCoinPair: (value) => void;
  balances: Array<UserBalanceResponse> | any;
  setBalances: (value) => void;
}

const cookies = new Cookies();

const defaultExchangeUserContext = {
  exchangeUser: null,
  exchangeUserBuyingOrders: [],
  exchangeUserSellingOrders: [],
  exchangeUserOrders: [],
  logIn: () => {},
  createExchangeUser: async () => undefined,
  getSystemOrders: () => {},
  logOut: () => undefined,
  setExchangeUser: () => undefined,
  setExchangeUserBuyingOrders: () => undefined,
  setExchangeUserSellingOrders: () => undefined,
  setExchangeUserOrders: () => undefined,
  loading: true,
  loadingInitial: true,
  tradingPairs: [],
  selectedCoinPair: null,
  setSelectedCoinPair: () => undefined,
  balances: null,
  setBalances: () => undefined,
};

export const ExchangeUserContext = createContext<ExchangeUserContextInterface>(defaultExchangeUserContext);

export const ExchangeUserProvider = React.memo(({ children }: any) => {
  const navigate = useNavigate();
  const [exchangeUser, setExchangeUser] = useState<ExchangeUserProps | null>(defaultExchangeUserContext.exchangeUser);
  const { user } = useContext(UserContext);
  const [exchangeUserOrders, setExchangeUserOrders] = useState([]);
  const [exchangeUserBuyingOrders, setExchangeUserBuyingOrders] = useState([]);
  const [exchangeUserSellingOrders, setExchangeUserSellingOrders] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [tradingPairs, setTradingPairs] = useState(Array<ExchangeTradingPair>);

  const [selectedCoinPair, setSelectedCoinPair] = useState<ExchangeTradingPair | null>(null);
  const [balances, setBalances] = useState<Array<UserBalanceResponse> | null>(null);
  const logOut = useCallback(() => {
    setSessionUuid('');
    cookies.remove('exchange-user', { path: AppRoot });
    setExchangeUser(null);
    navigate(generatePath(`/${AuthRoot}/${AuthRoutes.LOGIN}`));
  }, [navigate]);

  const logIn = useCallback(() => {
    setLoading(true);
    if (user) {
      setSessionUuid(user.uuid);
      getMyUser()
        .then((usr) => {
          cookies.set('exchange-user', usr, { path: AppRoot, secure: true });
          setExchangeUser(usr as ExchangeUserProps);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  const createExchangeUser = useCallback(async () => {
    await new Promise<boolean>((resolve, reject) => {
      if (!user) reject();
      if (user) {
        setSessionUuid(user.uuid);
        createUser(user).then((usr) => {
          setExchangeUser((prev) => usr as ExchangeUserProps);
          cookies.set('exchange-user', usr, { path: AppRoot, secure: true });
          resolve(true);
        });
      }
    });
  }, [user]);

  const getTradingPairs = useCallback(() => {
    setLoading(true);
    if (user) {
      setSessionUuid(user.uuid);
      exchangeGetTradingPairs()
        .then((results) => {
          setTradingPairs(results);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            logOut();
          }
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  const getBalances = useCallback(() => {
    setLoading(true);
    if (user) {
      setSessionUuid(user.uuid);
      exchangeGetBalances()
        .then((results) => {
          setBalances(results);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            logOut();
          }
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  const providerValues = useMemo(
    () => ({
      exchangeUser,
      exchangeUserOrders,
      exchangeUserBuyingOrders,
      exchangeUserSellingOrders,
      logIn,
      logOut,
      setExchangeUserOrders,
      createExchangeUser,
      setExchangeUser,
      setExchangeUserBuyingOrders,
      setExchangeUserSellingOrders,
      loading,
      loadingInitial,
      tradingPairs,
      selectedCoinPair,
      setSelectedCoinPair,
      balances,
      setBalances,
    }),
    [
      exchangeUser,
      exchangeUserOrders,
      exchangeUserBuyingOrders,
      exchangeUserSellingOrders,
      logIn,
      logOut,
      setExchangeUserOrders,
      createExchangeUser,
      setExchangeUser,
      setExchangeUserBuyingOrders,
      setExchangeUserSellingOrders,
      loading,
      loadingInitial,
      tradingPairs,
      selectedCoinPair,
      setSelectedCoinPair,
      balances,
      setBalances,
    ],
  );

  useLayoutEffect(() => {
    if (user) {
      setLoadingInitial(true);

      setSessionUuid(user.uuid);
      FeatureFlags.on(FeatureFlagsEvents.EXCHANGE_ENABLED_SPLIT, (treatment) => {
        if (treatment === 'on') {
          getBalances();
          getTradingPairs();
          getMyUser()
            .then((usr) => {
              setExchangeUser(usr as ExchangeUserProps);
            })
            .catch((_error) => {
              console.log(_error);
            })
            .finally(() => setLoadingInitial(false));
        }
      });
    }
    setLoadingInitial(false);
  }, [getBalances, getTradingPairs, user]);

  return (
    <ExchangeUserContext.Provider value={providerValues}>{!loadingInitial && children}</ExchangeUserContext.Provider>
  );
});
