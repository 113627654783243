import * as FullStory from '@fullstory/browser';
import { SplitFactory } from '@splitsoftware/splitio-react';
import Tap from '@tapfiliate/tapfiliate-js';
import cx from 'classnames';
import { PropsWithChildren, useContext, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Footer, Header } from 'src/common/components';
import { PageThemeContext, PageThemeProvider } from 'src/common/context/PageThemeContext';
import { UserProvider } from 'src/common/context/UserContext';
import { Env } from 'src/common/env';
import usePageTracking from 'src/common/hooks/usePageTracking';
import useSplit from 'src/common/hooks/useSplit';
import { AppRoot } from 'src/routes/app';
import { AppRouter } from 'src/routes/app/AppRouter';
import { AuthRouter } from 'src/routes/auth';

import { CheckIcon } from '@heroicons/react/outline';

import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AnimatePresence } from 'framer-motion';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CartProvider } from './common/context/CartContext';
import { ShardsProvider } from './common/context/ShardsContext';
import 'react-toastify/dist/ReactToastify.css';
import { CartPanel } from './common/components/cart-panel/CartPanel';
import Sidebar from './common/components/exchange/Sidebar/Sidebar';
import { CheckoutProvider } from './common/context/CheckoutContext';
import { ExchangeProvider } from './common/context/ExchangeContext';
import { ExchangeUserProvider } from './common/context/ExchangeUserContext';
import { AuthRoot } from './routes/auth/auth-routes';

const queryClient = new QueryClient();

export const Main = ({ children }: PropsWithChildren<any>) => {
  const { theme, width, showTopPadding } = useContext(PageThemeContext);
  const background = theme === 'white' ? 'bg-white shadow' : 'bg-gray-100';
  const maxWidth = width === 'constrained' ? 'max-w-7xl p-4' : 'w-full py-4';
  const topPadding = showTopPadding ? '' : 'pt-0';

  return (
    <main className={cx('z-10 flex flex-col flex-grow ', background)}>
      <div className={cx('flex flex-col flex-grow mx-auto justify-center content-center', maxWidth, topPadding)}>
        {children}
      </div>
    </main>
  );
};

const WrappedApp = () => {
  const [searchParams] = useSearchParams();

  usePageTracking();
  useEffect(() => {
    if (Env.fullstoryOrgId) {
      const devMode = Env.fullstoryDevMode ? true : ['development'].includes(Env.get('NODE_ENV') ?? '');
      FullStory.init({ orgId: Env.fullstoryOrgId, devMode });
    }

    if (Env.tapFiliateId) {
      Tap.init(Env.tapFiliateId, {}, () => {}, { referral_code: searchParams.get('ref') });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <PageThemeProvider>
        <ShardsProvider>
          <CartProvider>
            <UserProvider>
              <CheckoutProvider>
                <ExchangeProvider>
                  <ExchangeUserProvider>
                    <AnimatePresence>
                      <Sidebar />
                    </AnimatePresence>
                    <Header />
                    <CartPanel />
                    <Main>
                      <Outlet />
                    </Main>
                    <Footer />
                    <ToastContainer autoClose={3000} position="top-right" limit={3} hideProgressBar />
                    <CookieConsent
                      buttonText={
                        <div className="flex flex-row gap-2 items-center">
                          Accept Cookies <CheckIcon width="1.3rem" />
                        </div>
                      }
                      style={{
                        backgroundColor: '#1e8efc',
                        fontWeight: '700',
                        padding: '0.75rem 1.5rem',
                        alignItems: 'center',
                      }}
                      buttonStyle={{
                        backgroundColor: '#145CB4',
                        color: '#ffffff',
                        fontWeight: '700',
                        padding: '1rem 1.5rem',
                        margin: '0',
                        borderRadius: '0.25rem',
                      }}
                    >
                      <div className="flex flex-row gap-4 items-center">
                        <FontAwesomeIcon icon={faCookieBite} className="flex-none" size="2x" />
                        <div className="flex-1">
                          We use cookies on this site to improve your experience.
                          <br />
                          By accepting, you agree to the use of cookies as described in our{' '}
                          <a href="/privacy-policy" className="text-white">
                            cookie policy
                          </a>
                          .
                        </div>
                      </div>
                    </CookieConsent>
                  </ExchangeUserProvider>
                </ExchangeProvider>
              </CheckoutProvider>
            </UserProvider>
          </CartProvider>
        </ShardsProvider>
      </PageThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const App = () => {
  const { splitSdkConfig, factoryAttributes } = useSplit();
  useEffect(() => {
    if (Env.gtmId) {
      TagManager.initialize({ gtmId: Env.gtmId });
    }
  }, []);

  return (
    <SplitFactory config={splitSdkConfig} attributes={factoryAttributes}>
      <BrowserRouter>
        <Routes>
          <Route element={<WrappedApp />}>
            <Route path={`${AppRoot}*`} element={<AppRouter />} />
            <Route path={`${AuthRoot}/*`} element={<AuthRouter />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </SplitFactory>
  );
};

export default App;
