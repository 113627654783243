import { useContext, useEffect } from 'react';
import { Select } from 'src/common/components/forms/Select';
import { ExchangeUserContext } from 'src/common/context/ExchangeUserContext';

export const CoinPairSelect = ({ selectAllAvailable = false }: { selectAllAvailable: boolean }) => {
  const { tradingPairs, selectedCoinPair, setSelectedCoinPair } = useContext(ExchangeUserContext);

  useEffect(() => {
    if (!selectedCoinPair && tradingPairs && !selectAllAvailable) {
      setSelectedCoinPair(tradingPairs[0]);
    }
  }, [tradingPairs, selectedCoinPair, setSelectedCoinPair]);

  return (
    <div className="w-48 min-w-48">
      <Select
        className="h-10 text-sm text-gray-400 uppercase font-normal text-[12px] outline-none border-0 focus:ring-0 font-poppins lg:bg-[#F4F4F4] "
        aria-label="Pair Options"
        value={
          selectedCoinPair?.uuid ||
          (selectAllAvailable ? 'all' : tradingPairs && tradingPairs[0] && tradingPairs[0].uuid)
        }
        onChange={(event) => {
          if (event.target.value === 'all') {
            setSelectedCoinPair(null);
          } else {
            setSelectedCoinPair(tradingPairs.find((cp) => cp.uuid === event.target.value));
          }
        }}
      >
        {selectAllAvailable ? (
          <option key="all" value="all">
            All
          </option>
        ) : null}
        {tradingPairs &&
          tradingPairs.map((pair) => (
            <option key={pair.uuid} value={pair.uuid}>
              {pair.base_coin_name} - {pair.quote_coin_name}
            </option>
          ))}
      </Select>
    </div>
  );
};
