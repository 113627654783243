import { toast } from 'react-toastify';
import './ErrorToast.scss';
import './SuccessToast.scss';
import './PendingToast.scss';

interface Props {
  type: string;
  text?: Element | string;
  position?: string | undefined;
  icon?: React.ReactNode;
}
const CustomToasts = ({ type, text, position, icon }: Props) => {
  let customToast;

  const customTypes = {
    success: () => {
      customToast = toast(
        <div className="flex flex-column">
          {icon}
          <span>{(text as any) || 'Success'}</span>
        </div>,
        {
          position: toast.POSITION[`${position}`],
          className: 'custom-toast-success',
        },
      );
    },
    pending: () => {
      customToast = toast(
        <div className="flex flex-column">
          {icon}
          <span>{(text as any) || 'Pending'}</span>
        </div>,
        {
          position: toast.POSITION[`${position}`],
          className: 'custom-toast-pending',
        },
      );
    },
    error: () => {
      customToast = toast(
        <div className="flex flex-column">
          {icon}
          <span>{(text as any) || 'We encountered a problem. Contact support@glassblock.io.'}</span>
        </div>,
        {
          position: toast.POSITION[`${position}`],
          className: 'custom-toast-error',
        },
      );
    },
  };
  customTypes[type]();
  return customToast;
};
CustomToasts.defaultProps = {
  position: 'TOP_RIGHT',
};
export default CustomToasts;
